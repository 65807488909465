<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>creat_by </label>
          <input v-model="form.creat_by" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 type="text">

          <div v-if="errors.creat_by" class="invalid-feedback">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>typespostes </label>
          <CustomSelect
              :key="form.typesposte"
              :columnDefs="['libelle']"
              :oldValue="form.typesposte"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.typesposte_id=data.id;form.typesposte=data}"
              :url="`${axios.defaults.baseURL}/api/typespostes-Aggrid1`"
              filter-key=""
              filter-value=""
          />
          <div v-if="errors.typesposte_id" class="invalid-feedback">
            <template v-for=" error in errors.typesposte_id"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>users </label>
          <CustomSelect
              :key="form.user"
              :columnDefs="['libelle']"
              :oldValue="form.user"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.user_id=data.id;form.user=data}"
              :url="`${axios.defaults.baseURL}/api/users-Aggrid1`"
              filter-key=""
              filter-value=""
          />
          <div v-if="errors.user_id" class="invalid-feedback">
            <template v-for=" error in errors.user_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button class="btn btn-primary" type="submit">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'CreateUserstypespostes',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'typesposteData',
    'usersData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        user_id: "",

        typesposte_id: "",

        creat_by: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/userstypespostes', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        user_id: "",
        typesposte_id: "",
        creat_by: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
      }
    }
  }
}
</script>
